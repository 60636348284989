<template>

  <div>
        <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
    <div>
        <div class="messages">
        <ul
v-if="messagesList.length>0"
class="list-group"
style="height:300px;width:100%;overflow-y:scroll;"
>
          <input
id="materialRequestId"
ref="invoiceMessage"
v-model="materialRequestId"
type="hidden"
name="materialRequestId"
>
            <li
v-for="item in messagesList"
:key="item"
class="list-group-item"
>
              <div
v-if="userId.toString()==item.user.user_id.toString()"
class="float-right"
>
              <h5>Me</h5>
              <span style="font-size:9px">{{ dateSimple(item.material_request_chat_created_time, 'YYYY-MM-DD HH:mm:ss') }}</span>
              <p>{{ item.material_request_chat_text }}</p>
              </div>
              <div
v-if="userId.toString()!=item.user.user_id.toString()"
class="float-left"
>
              <h5>{{ item.user.user_fullname }}</h5>
              <span style="font-size:9px">{{ dateSimple(item.material_request_chat_created_time, 'YYYY-MM-DD HH:mm:ss') }}</span>
              <p>{{ item.material_request_chat_text }}</p>
              </div>
            </li>
        </ul>
    <div v-if="messagesList.length==0">
      No message
      </div><br>
          <form
id="send_message_form"
method="post"
action=""
>
            <b-row>
            <b-col
          cols="11"
          md="9"
          >
            <input
v-model="messageBox"
type="text"
class="form-control"
placeholder="Write your message"
>
            </b-col>
            <b-col
          cols="11"
          md="3"
><input
type="button"
class="btn btn-primary pull-right"
value="Send"
@click="sendMessage()"
></b-col>
          </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Filters -->
    <unites-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-tabs>
        <b-tab title="Active">
          <b-tabs>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPRPlanListTable"
        class="position-relative"
        :items="fetchPRPlanes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: To -->
        <template #cell(Item)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.item_name }} ({{ data.item.item.item_code }})</span>
          </div>
        </template>
        <!-- Column: MRNumber -->
        <template #cell(MRNumber)="data">
          <div class="text-nowrap">
            <feather-icon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.material_request!=null?data.item.material_request.material_request_number:"-" }}</span>
          </div>
        </template>
        <!-- Column: CC -->
        <template #cell(Unit)="data">
          <div class="text-nowrap">
            <feather-icon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.item.unit!=null?data.item.item.unit.unit_name:"-" }}</span>
          </div>
        </template>
        <!-- Column: Reason -->
        <template #cell(QtyRequested)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.purchase_request_detail_item_qty_requested }}
          </b-badge>
        </template>
        <!-- Column: DateCreated -->
        <template #cell(DateCreated)="data">
            {{ dateSimple(data.item.purchase_request_detail_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
         <!-- Column: Action -->
         <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                  v-if="userRole=='warehouse-cabang'"
              >
              <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="userRole=='warehouse-cabang'"
                  @click="deletePRPlan(data.item.purchase_request_detail_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPRPlan"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tabs>
      </b-tab>

      <b-tab title="Void">
          <b-tabs>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPageVoid"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryVoid"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPRPlanListTableVoid"
        class="position-relative"
        :items="fetchPRPlanesVoid"
        responsive
        :fields="tableColumnsVoid"
        primary-key="id"
        :sort-by.sync="sortByVoid"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescVoid"
      >

        <!-- Column: To -->
        <template #cell(Item)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.item_name }} ({{ data.item.item.item_code }})</span>
          </div>
        </template>
        <!-- Column: CC -->
        <template #cell(Unit)="data">
          <div class="text-nowrap">
            <feather-icon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.item.unit!=null?data.item.item.unit.unit_name:"-" }}</span>
          </div>
        </template>
        <!-- Column: MRNumber -->
        <template #cell(MRNumber)="data">
          <div class="text-nowrap">
            <feather-icon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.material_request!=null?data.item.material_request.material_request_number:"-" }}</span>
          </div>
        </template>
        <!-- Column: Reason -->
        <template #cell(QtyRequested)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.purchase_request_detail_item_qty_requested }}
          </b-badge>
        </template>
        <!-- Column: DateCreated -->
        <template #cell(DateCreated)="data">
            {{ dateSimple(data.item.purchase_request_detail_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
         <!-- Column: VoidBy -->
         <template #cell(VoidBy)="data">
          <span class="align-text-top text-capitalize">{{ data.item.deleted_by!=null?data.item.deleted_by.user_fullname:"-" }}</span>
            </template>
         <!-- Column: VoidTime -->
         <template #cell(VoidTime)="data">
          <span class="align-text-top text-capitalize">{{ data.item.purchase_request_detail_deleted_time!=null?dateSimple(data.item.purchase_request_detail_deleted_time, 'YYYY-MM-DD HH:mm:ss'):"-" }}</span>
            </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaVoid.from }} to {{ dataMetaVoid.to }} of {{ dataMetaVoid.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageVoid"
              :total-rows="totalPRPlanVoid"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tabs>
      </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem, BTab, BTabs,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import PRPlanList from './List'
import PRPlanModule from './PRPlanModule'

const userRole = ''

const messageBox = ''
const to = ''
const cc = ''
const reason = ''
const materialRequestId = ''
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
export default {
  components: {
    BTabs,
    BTab,
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // PRPlanesListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
    items,
    statusItems,
    materialRequestId,
    userId,
    messagesList,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refPRPlanListTable.refresh()
    this.$refs.refPRPlanListTableVoid.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-material-request'
      // Register module
      if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, PRPlanModule)
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

    const isAddNewPRPlanSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchPRPlanes,
      fetchPRPlanesVoid,
      fetchRoles,
      tableColumns,
      tableColumnsVoid,
      perPage,
      perPageVoid,
      currentPage,
      currentPageVoid,
      totalPRPlan,
      totalPRPlanVoid,
      dataMeta,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      searchQueryVoid,
      sortBy,
      sortByVoid,
      isSortDirDesc,
      isSortDirDescVoid,
      refPRPlanListTable,
      refPRPlanListTableVoid,
      refetchData,

      // UI
      resolvePRPlanRoleVariant,
      resolvePRPlanRoleIcon,
      resolvePRPlanStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = PRPlanList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewPRPlanSidebarActive,
      userRole,
      fetchPRPlanes,
      fetchPRPlanesVoid,
      fetchRoles,
      tableColumns,
      tableColumnsVoid,
      perPage,
      perPageVoid,
      currentPage,
      currentPageVoid,
      totalPRPlan,
      totalPRPlanVoid,
      dataMeta,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      searchQueryVoid,
      sortBy,
      sortByVoid,
      isSortDirDesc,
      isSortDirDescVoid,
      refPRPlanListTable,
      refPRPlanListTableVoid,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePRPlanRoleVariant,
      resolvePRPlanRoleIcon,
      resolvePRPlanStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
      editPRPlan(data) {
          this.$cookies.set('materialRequestEdit', data)
          this.$router.push({ name: 'apps-material-request-edit' })
      },
      confirmPRPlan(data) {
          this.$cookies.set('materialRequestConfirm', data)
          this.$router.push({ name: 'apps-material-request-confirm' })
      },
      sendPRPlan(data) {
          this.$cookies.set('materialRequestSend', data)
          this.$router.push({ name: 'apps-material-request-send' })
      },
    addNew() {
          this.$router.push({ name: 'apps-material-request-create' })
    },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    showDetail(item) {
      this.$cookies.set('materialRequestDetail', item)
      this.$router.push({ name: 'apps-material-request-detail' })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get('userPermissions').split(',')
      return permissions.includes(permission) ? true : false
    },
    showMessages(id) {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      this.materialRequestId = id
      this.modalMessage = true
    axios
      .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          console.log('Fetching Data')
      if (response.data.data) {
      this.messagesList.splice(0, this.messagesList.length)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Message success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        response.data.data.map(value => {
          this.messagesList.push(value)
          return value.material_request_chat_text
        })
        console.log(this.messagesList)
        console.log()
      }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
    deletePRPlan(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_PR_PLAN}${id}`, {
          headers,
        })
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refPRPlanListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async sendMessage() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      const id = this.materialRequestId
      const Message = this.messageBox
      const body = {
        material_request_chat_material_request_id: id,
        material_request_chat_text: Message,
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.messageBox = ''
    axios
      .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
      .then(response2 => {
        if (response2.data.success === true) {
          console.log('Fetching Data')
      if (response2.data.data) {
      messagesList.splice(0, messagesList.length)
        response2.data.data.map(value => {
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
