import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refPRPlanListTable = ref(null)
  const refPRPlanListTableVoid = ref(null)
  const tableColumns = [
    { key: 'Item', sortable: true },
    { key: 'Unit', sortable: false },
    { key: 'MRNumber', sortable: false },
    { key: 'QtyRequested', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'Action', sortable: false },
  ]
  const tableColumnsVoid = [
    { key: 'Item', sortable: true },
    { key: 'Unit', sortable: false },
    { key: 'MRNumber', sortable: false },
    { key: 'QtyRequested', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: true },
    { key: 'VoidTime', sortable: true },
  ]
  const perPage = ref(10)
  const perPageVoid = ref(10)
  const totalPRPlan = ref(0)
  const totalPRPlanVoid = ref(0)
  const currentPage = ref(1)
  const currentPageVoid = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryVoid = ref('')
  const sortBy = ref('id')
  const sortByVoid = ref('id')
  const isSortDirDesc = ref(true)
  const isSortDirDescVoid = ref(true)
  const roleFilter = ref(null)
  const roleFilterVoid = ref(null)
  const statusFilter = ref(null)
  const statusFilterVoid = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPRPlanListTable.value ? refPRPlanListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPRPlan.value,
    }
  })
  const dataMetaVoid = computed(() => {
    const localItemsCount = refPRPlanListTableVoid.value ? refPRPlanListTableVoid.value.localItems.length : 0
    return {
      from: perPage.value * (currentPageVoid.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPageVoid.value - 1) + localItemsCount,
      of: totalPRPlanVoid.value,
    }
  })

  const refetchData = () => {
    refPRPlanListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc, currentPageVoid, perPageVoid, searchQueryVoid, roleFilterVoid, statusFilterVoid, sortByVoid, isSortDirDescVoid], () => {
    refetchData()
  })

  const fetchPRPlanes = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    const body = {
      limit: perPage.value,
      offset: to,
      search: searchQuery.value,
    }
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PR_PLAN}`, body, { headers })
      .then(response => {
        totalPRPlan.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPRPlanesVoid = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    const body = {
      limit: perPage.value,
      offset: to,
      search: searchQuery.value,
      is_deleted: true,
    }
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PR_PLAN}`, body, { headers })
      .then(response => {
        totalPRPlanVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchRoles = () => {
    const userToken = this.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
      .then(response => {
        console.log(response)
        const temp = [
          { label: 'Admin', value: 'admin' },
          { label: 'Author', value: 'author' },
          { label: 'Editor', value: 'editor' },
          { label: 'Maintainer', value: 'maintainer' },
          { label: 'Subscriber', value: 'subscriber' },
        ]
        return temp
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPRPlanes,
    fetchPRPlanesVoid,
    fetchRoles,
    tableColumns,
    tableColumnsVoid,
    perPage,
    currentPage,
    totalPRPlan,
    dataMeta,
    dataMetaVoid,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPRPlanListTable,
    refPRPlanListTableVoid,
    perPageVoid,
    currentPageVoid,
    totalPRPlanVoid,
    searchQueryVoid,
    sortByVoid,
    isSortDirDescVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
